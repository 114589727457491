$primary-blue:#8FAFFB;
$secondary-blue: #213C7A;

$primary-blue-shade: #d7fffe;
$primary-blue-trans: #d6fffe80;
$secondary-blue-trans: rgba(33, 60, 122, 0.5);

$off-white: #fffff4;
$off-black: #414a4c;

$off-black-trans: #424b4dcc;
$off-white-trans: #fffff41e;

$border-radius: 10px;
$base-spacing: 10px;

$primary-green: #cafb76;
$secondary-green:#637a3a;

$primary-yellow: #FBEC69;
$secondary-yellow: #7A7333;

$primary-red: #FB544B;
$secondary-red: #7A0236;