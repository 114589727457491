@import "../../variables.scss";

.navBar {
  width: 100%;
  height: 10vh;
  position: fixed;
  z-index: 2;

  .navBarContainer {
    width: 100%;
    height: 100%;
    background-color: $off-white;
    color: $off-black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $base-spacing;
    border-radius: 0 0 $border-radius $border-radius;
    border: 2px solid $off-black;
    border-top: none;

    h1 {
      letter-spacing: 2px;
      font-size: 25px;
    }
    span {
      color: $primary-red;
    }

    .icon {
      font-size: 20px;
      color: $off-black-trans;
      &:hover {
        color: $primary-red;
        cursor: pointer;
      }
    }
  }
  .navBarSubContainer {
    width: 100%;
    position: fixed;
    display: flex;
    padding: 0 10px;

    .navBtn {
      margin-right: 10px;
      background-color: $primary-blue;
      color: $secondary-blue;
      border: 2px solid $secondary-blue;
      span {
        display: none;
      }
      &:hover {
        cursor: pointer;
        background-color: $primary-blue-shade;
        border: 2px solid $primary-blue;
        border-top: none;
      }
    }

    .navBtn,
    .link {
      padding: $base-spacing $base-spacing * 2;
      border-radius: 0 0 $border-radius $border-radius;
      font-weight: bold;
      z-index: 2;
      border-top: none;
      letter-spacing: 1.2px;
      font-size: 12px;
    }

    .link {
      color: $off-white;
      background-color: $off-black-trans;
      border: 2px solid $off-black;
      border-right: none;
      border-top: none;
      text-decoration: none;
      border-radius: 0 0 0 $border-radius;

      &:last-child {
        border-right: 2px solid $off-black;
        border-left: none;
        border-radius: 0 0 $border-radius 0;
      }

      &:hover {
        background-color: $primary-green;
        color: $secondary-green;
        cursor: pointer;
      }
    }
    .linkHighlight {
      background-color: $primary-green;
      color: $off-black;
      &:hover {
        color: $off-black;
        cursor: unset;
      }
    }
  }
}
.inputModal {
  background-color: $off-black-trans;
  position: fixed;
  height: 100vh;
  width: 100%;
  padding-top: 15vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    height: 80%;
    width: 90%;
  }
}

@media only screen and (min-width: 764px) {
  .navBar {
    padding: 0 50px;

    .navBarContainer {
      padding: 0 $base-spacing * 3;

      h1 {
        font-size: 35px;
      }
      span {
        color: $primary-red;
      }

      .icon {
        font-size: 25px;
      }
    }
    .navBarSubContainer {
      padding: 0 30px;

      .navBtn {
        margin-right: 10px;
      }

      .navBtn,
      .link {
        font-size: 15px;
      }

    }
  }
  .inputModal {
    padding: 0;
    background-color: $off-black-trans;
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      height: 50%;
      width: 30%;
    }
  }
}

