@import "../../variables.scss";

.todoList {
  border: 2px solid $secondary-green;
  border-radius: $border-radius;
  overflow: hidden;
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  background-color: $off-white;
  color: $secondary-green;

  h2 {
    height: 20%;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: $primary-green;
    color: $secondary-green;
    padding: 0 $base-spacing;
    font-weight: 350;
    display: flex;
    align-items: center;
    font-size: 18px;
  }

  > p {
    height: 50%;
    border-top: 1px solid $secondary-green;
    border-bottom: 1px solid $secondary-green;
    padding: $base-spacing;
    overflow-wrap: break-word;
  }

  div {
    width: 100%;
    display: flex;

    p {
      padding: $base-spacing;
      display: inline-block;
      width: 50%;
      height: 100%;
      font-size: 14px;

      &:first-child {
        background-color: $primary-green;
      }
    }
  }
  div {
    height: 20%;
    width: 100%;

    button {
      height: 100%;
      width: 50%;
      text-transform: uppercase;
      background: none;
      border: none;
      color: $secondary-green;
      border-top: 1px solid $secondary-green;

      &:hover {
        background-color: $primary-red;
        cursor: pointer;
      }

      &:first-child:hover {
        background-color: $primary-green;
      }
    }
  }
}

.red {
  color: $secondary-red;
  border: 2px solid $secondary-red;
  h2 {
    background-color: $primary-red;
    color: $secondary-red;
  }

  > p {
    border-top: 1px solid $secondary-red;
    border-bottom: 1px solid $secondary-red;
  }

  div {
    p {
      &:first-child {
        background: $primary-red;
      }
    }
  }
  div {
    button {
      color: $secondary-red;
      border-top: 1px solid $secondary-red;

      &:hover {
        background: $primary-red;
      }
    }
  }
}

.yellow {
  color: $secondary-yellow;
  border: 2px solid $secondary-yellow;
  h2 {
    background-color: $primary-yellow;
    color: $secondary-yellow;
  }

  > p {
    border-top: 1px solid $secondary-yellow;
    border-bottom: 1px solid $secondary-yellow;
  }

  div {
    p {
      &:first-child {
        background: $primary-yellow;
      }
    }
  }
  div {
    button {
      color: $secondary-yellow;
      border-top: 1px solid $secondary-yellow;

      &:hover {
        background: $primary-yellow;
      }
    }
  }
}

.fadeIn {
  -webkit-animation: fadeIn 0.6s linear both;
  animation: fadeIn 0.6s linear both;
}

.puffOut {
  -webkit-animation: puffOut 1.5s both;
  animation: puffOut 1.5s both;
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes puffOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puffOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
