@import "../../variables.scss";

.itemTile {
  background-color: $off-white;
  height: 100%;
  color: $off-black;

  p {
    font-size: 12px;
    text-transform: uppercase;
    padding: $base-spacing/2;

    &:first-child {
      padding-bottom: 0;
    }
  }

  .completedTitle {
    border: 1px solid $secondary-green;
    background-color: $primary-green;
    border-radius: $border-radius;
    margin: $base-spacing/2 0;
    text-align: center;
    font-size: 12px;
  }
}

@media only screen and (min-width: 764px) {
  .itemTile {
    p {
    }

    .completedTitle {
      border: 2px solid $secondary-green;
    }
  }
}
@media only screen and (min-width: 1024px) {
  .itemTile {
    padding: $base-spacing;
    p {
      padding: 0;
    }
    .completedTitle, p {
      font-size: 18px;
    }
  }
}
