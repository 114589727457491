@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
    background-image:  url(./assets/imgs/hip-square.png);
}

button {
    transition: background-color 0.8s;
}