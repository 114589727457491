@import "../../variables.scss";

.completed {
  width: 100%;
  min-height: 100vh;
  padding: 20vh 10px 10px 10px;

  .calendarContainer {
    height: 100%;
    width: 100%;
    border-radius: $border-radius;
    border: 3px solid $off-black;
    overflow: hidden;
    background-color: $off-black-trans;

    .calendarMonth {
      width: 100%;
      display: flex;
      justify-content: space-between;

      button {
        flex-grow: 1;
        background-color: $off-black-trans;
        border: none;
        font-size: 30px;
        color: $off-white;
        border-left: 2px solid $off-white;
        cursor: pointer;

        &:hover {
          background-color: $off-white-trans;
          color: $off-black;
        }

        &:first-child {
          border-left: none;
          border-right: 2px solid $off-white;
        }
      }

      h2 {
        flex-grow: 1;
        height: 100%;
        font-size: 30px;
        text-align: center;
        color: $off-white;
        text-transform: uppercase;
      }
    }

    .calendarYear {
      display: flex;
      width: 100%;
      background-color: $primary-yellow;
      border-top: 2px solid $off-black;

      p {
        padding: $base-spacing/2;
        flex-grow: 1;
        text-align: center;
        font-size: 14px;
        color: $off-black;
        border-left: 2px solid $off-black;
        border-right: 2px solid $off-black;
      }

      button {
        font-size: 12px;
        padding: $base-spacing/2;
        width: 30%;
        border: none;
        background-color: $primary-red;
        text-transform: uppercase;
        cursor: pointer;
        color: $off-black;

        &:hover {
          background-color: $primary-green;
          color: $secondary-green;
        }
      }
      .completedHighLight,
      .todoHighLight {
        background-color: $primary-green;
        border: none;

        &:hover {
          cursor: unset;
        }
      }
    }

    .calendarDates {
      overflow: hidden;
      width: 100%;
      height: 70%;
      display: grid;
      grid-gap: 2px;
      padding-top: 2px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-auto-rows: minmax(50px, auto);
    }
  }
}

@media only screen and (min-width: 764px) {
  .completed {
    padding: 20vh 50px 50px 50px;

    .calendarContainer {
      .calendarMonth {
        button {
          font-size: 50px;
        }

        h2 {
          font-size: 40px;
        }
      }

      .calendarYear {
        p {
          font-size: 20px;
          width: 30%;
        }

        button {
          font-size: 18px;
          flex-grow: 1;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .calendarContainer {
    .calendarMonth {
      button {
        font-size: 40px;
      }

      h2 {
        font-size: 50px;
      }
    }
  }
}
