@import "../../variables.scss";

.todoListInputs {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: $secondary-blue 2px solid;
  border-radius: $border-radius;
  overflow: hidden;
  color: $secondary-blue;

  input {
    outline: none;
    border: none;
    padding: $base-spacing;
    color: $secondary-blue;
    background-color: $off-white;

    &:focus {
      background-color: $primary-blue-shade;
    }

    &::placeholder {
      color: $secondary-blue;
    }
  }

  textarea {
    padding: $base-spacing;
    height: 50%;
    border: none;
    box-shadow: none;
    background-color: $off-white;
    border-top: 1px solid $secondary-blue;
    border-bottom: 1px solid $secondary-blue;

    &:focus {
      outline: none;
      background-color: $primary-blue-shade;
    }

    &::placeholder {
      color: $secondary-blue;
    }
  }

  > div {
    height: 20%;
  }

  div {
    width: 100%;
    display: flex;

    h2 {
      padding: 0 $base-spacing;
      text-transform: uppercase;
      width: 50%;
      background: $primary-blue;
      font-weight: 350;
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    p {
      font-size: 14px;
      width: 50%;
      height: 100%;
      background-color: $primary-blue;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      padding: 0 $base-spacing;
    }

    input {
      height: 100%;
      width: 50%;

      text-transform: uppercase;

      &:focus {
        background-color: $primary-blue-shade;
      }
    }
  }

  button {
    height: 20%;
    background-color: $off-white;
    border: none;
    border-top: 1px solid $secondary-blue;
    color: $secondary-blue;
    text-transform: uppercase;
    transition: background-color .8s;
    

    &:hover {
      background-color: $primary-blue;
      cursor: pointer;
    }
  }
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  color: $secondary-blue;

  &:hover {
    background-color: unset;
  }
}

[type="date"] {
  background-color: $off-white;
}
