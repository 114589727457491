@import "../../variables.scss";

.landingPage {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: $off-black;

  > div {
    width: 90%;
    height: 80%;
    border: 2px solid $off-black;
    border-radius: $border-radius;
    overflow: hidden;
    color: $off-black;

    h2 {
      height: 25%;
      padding: $base-spacing * 2 $base-spacing;
      text-align: center;
      font-size: 40px;
      background-color: $primary-green;
      width: 100%;
    }

    div {
      height: 50%;
      width: 100%;
      color: $off-black;
      background-color: $off-white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 3px solid $off-black;
      border-bottom: 3px solid $off-black;

      p {
        font-size: 40px;
      }
    }

    .google {
      width: 100%;
      height: 25%;
      background-color: $primary-yellow;
      padding: $base-spacing * 2;
      font-size: 20px;

      &:hover {
        background-color: $primary-green;
        cursor: pointer;
      }
    }
  }

  .changingWord {
    color: $primary-red;
  }
  .changingWord:after {
    content: " ";
    animation: spin 5s ease-in-out infinite;
  }

  @keyframes spin {
    from {
      content: "Down...";
    }
    to {
      content: "Done...";
    }
  }
}

@media only screen and (min-width: 764px) {
  .landingPage {
    > div {
      width: 50%;
      height: 75%;

      h2 {
        height: 25%;

        font-size: 50px;

        width: 100%;
      }

      div {
        height: 50%;
        width: 100%;

        p {
          font-size: 50px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .landingPage {
    > div {
      width: 25%;
      height: 50%;

      h2 {
        height: 25%;
        padding: $base-spacing;
        font-size: 40px;
        width: 100%;
      }

      div {
        height: 50%;
        width: 100%;

        p {
          font-size: 40px;
        }
      }
    }
  }
}
